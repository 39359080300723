module.exports = {
  pathPrefix: '',
  siteUrl: 'https://www.armanism.com',
  siteTitle: 'Arman Ali',
  siteDescription: 'Arman Ali is a Linux Server Administrator with having 5 years of experience in various domains like Cloud Computing, Cloud Migration, WordPress, Linux and much more.',
  author: 'Arman Ali',
  postsForArchivePage: 3,
  defaultLanguage: 'en_US',
  disqusScript: process.env.DISQUS_SCRIPT || 'https://armanism.disqus.com/embed.js',
  pages: {
    home: '/',
    blog: 'blog',
    contact: 'contact',
    resume: 'resume',
    tag: 'tags',
  },
  social: {
    github: 'https://github.com/armanism24',
    facebook: 'https://www.facebook.com/armanism24',
    twitter: 'https://twitter.com/armanism24',
    instagram: 'https://www.instagram.com/armanism24',
    rss: '/rss.xml',
  },
  contactFormUrl: process.env.CONTACT_FORM_ENDPOINT || 'https://getform.io/f/8a5770c1-9be4-4acb-8c1f-5b671dbf0e62',
  googleAnalyticTrackingId: process.env.GA_TRACKING_ID || '270086410',
  tags: {
    ubuntu: {
      name: 'ubuntu',
      description: 'Ubuntu is one of the most famous open-source Linux operating systems.',
      color: '#cc040c',
    },
    nginx: {
      name: 'nginx',
      description: 'NGINX is a powerful web Server that can also be used as a reverse proxy, load balancer, and HTTP cache.',
      color: '#0F964D',
    },
    apache: {
      name: 'apache',
      description: 'apache is a web Server that power 36.1% of the websites in the world.',
      color: '#C20323',
    },
    programming: {
      name: 'programming',
      description: 'Computer Programming is a set of instructions that perform certain tasks.',
      color: '#E067EA',
    },
    seo: {
      name: 'seo',
      description: 'SEO ( Search Engine Optimization ) is way to improve your ranking on search engine.',
      color: '#6b1aaa',
    },
  },
};
